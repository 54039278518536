<template>
    <div class="card">
        <div class="card-header">
            <h3><i class="ph ph-funnel"></i> {{ title }}</h3>
        </div>
        <div class="card-body">
            {{ selectedTableFields }} {{ fieldsListData }}
            <div v-loading="modalLoading" class="add-contact-type-popup">
                Select filter to add columns
                <el-select v-model="currentFilter" clearable style="width: 100%" class="mb-1"
                    @change="updateSelectedColumn">
                    <el-option v-for="filter in entityAllFilters" :key="filter._id" :label="filter.filter_name"
                        :value="filter._id">{{ filter.filter_name }}</el-option>
                </el-select>
                <div v-if="getCurrentFilter && getCurrentFilter.tableFieldGroupBy">
                    <el-checkbox-group v-model="filterColumns" :min="1">
                        <ol class="v_list">
                            <li><el-checkbox label="count">Count</el-checkbox></li>

                            <li>
                                <el-checkbox v-for="(field, index) of getTotalFields" :label="field.key"
                                    :key="field.key + 'field_count' + index">{{
                                        `${field.template_name} - ${field.label} `
                                    }}</el-checkbox>
                            </li>

                            <li style="margin-top: 10px">
                                <el-checkbox label="sub_group/count" v-if="
                                    getCurrentFilter && getCurrentFilter.tableFieldSubGroupBy
                                ">Sub group Count</el-checkbox>
                            </li>

                            <li>
                                <template v-if="
                                    getCurrentFilter && getCurrentFilter.tableFieldSubGroupBy
                                ">
                                    <el-checkbox v-for="(field, index) of getTotalFields"
                                        :label="'sub_group/' + field.key" :key="field.key + index + 'fieldcheck'">
                                        Sub group {{ `${field.template_name} - ${field.label} ` }}
                                    </el-checkbox>
                                </template>
                            </li>
                        </ol>
                    </el-checkbox-group>
                    <!-- <div
                v-if="
                  this.getField(getCurrentFilter.tableFieldGroupBy) &&
                  this.getField(getCurrentFilter.tableFieldGroupBy)
                    .input_type == 'ENTITY' &&
                  !getCurrentFilter.tableFieldSubGroupBy
                "
              >
                Include{{
                  this.getField(this.getCurrentFilter.tableFieldGroupBy).label
                }}
                variables:
                <el-select
                  placeholder="Select variable"
                  v-model="filterVariableColumns"
                  multiple
                  allow-create
                >
                  <el-option
                    v-for="(op, i) in allOtherEntityFields"
                    :key="op.key + '_' + i"
                    :value="op.template_id + '#' + op.key"
                    :label="op.label"
                  >
                  </el-option>
                </el-select>
              </div> -->
                </div>
                <div v-else>
                    <el-input placeholder="Search Field" v-model="filterText">
                        <template #prefix>
                            <i class="el-icon-search" style="margin-left: 10px; margin-top: 10px"></i>
                        </template>
                    </el-input>

                    <div class="tree-container">
                        <el-tree class="filter-tree" :data="fieldsListData" :props="tree_props" default-expand-all
                            show-checkbox highlight-current :filter-node-method="filterNode" ref="tree" node-key="id"
                            :default-checked-keys="selectedTableFields" @check-change="checkedNodes"
                            v-if="!refresh"></el-tree>
                    </div>

                    <el-collapse>
                        <el-collapse-item>
                            <template slot="title">Drag fields to change the column order</template>

                            <div>
                                <el-scrollbar class="elementsScroll">
                                    <table class="table table-striped"
                                        style="z-index: 1000; border: 10px; margin: 5px 0px">
                                        <tr>
                                            <draggable v-model="treeCheckedNodes" tag="tr">
                                                <th v-for="(column, index) in treeCheckedNodes" :key="index">
                                                    <el-tag type="info">{{ column.label }}</el-tag>
                                                </th>
                                            </draggable>
                                        </tr>
                                    </table>
                                </el-scrollbar>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <div class="card-footer text-muted">

            <span slot="footer" class="dialog-footer">
                <span v-if="modalLoading">
                    <button class="btn btn-outline-primary btn-sm m-lr-1" type="button">
                        <span class="spinner-border spinner-border-sm"></span>

                        Loading..
                    </button>
                </span>

                <span v-else>
                    <button class="btn btn-outline-info btn-sm m-lr-1 float-right" type="button" @click="updateFilterColumn"
                        v-if="currentFilter">
                        <i class="fa fa-rocket fa-fade"></i> &nbsp; Save & apply...
                    </button>

                    <button class="btn btn-outline-info btn-sm m-lr-1 float-right" type="button" @click="update" v-else>
                        <i class="fa fa-rocket fa-fade"></i> &nbsp; Apply.....
                    </button>
                </span>
            </span>
        </div>
    </div>

</template>

<script>
import draggable from "vuedraggable";
export default {
    name: 'EN-Settings',
    props:{
        title:{
            type:String,
            default:"Settings"
        },
        type:{
            type: String,
            default: 'Settings'
        },
        updateFilterColumn:{
            type:Function,
            default:() => {},
            require:true
        },
        update:{
            type: Function,
            default: () => {},
            require:true
        },
        updateSelectedColumn:{
            type: Function,
            default:()=>{},
            require:true
        },
        currentFilter:{
            type:String,
            default:'',
        },
        modalLoading:{
            type:Boolean,
            default:false,
        },
        entityAllFilters:{
            type:Array,
            default:() => [],
        },
        getCurrentFilter:{
            type:Object,
            default:() => {}
        },
        filterNode:{
            type:Function,
            default:() => {}
        },
        selectedTableFields:{
            type:Array,
            default:()=>[]
        },
        checkedNodes:{
            type:Function,
            default:()=>{}
        },
        treeCheckedNodes:{
            type: Array,
            default:()=>[]
        },
        refresh:{
            type:Boolean,
            default:false,
        },
        fieldsListData:{
            type: Array,
            default:()=>[],
        }

    },
    components:{
        draggable
    },
    data() {
        return {
            filterText:'',
            tree_props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },
        }
    }
}
</script>